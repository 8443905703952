<template slot-scope="{ yhcc }">
  <div class="container">
    <header>
      <div @click="show = true" class="left">
        <van-icon
          name="arrow-left"
          size="24"
          color="#ffffff"
          @click="$router.push('/')"
        />
        <img src="@/assets/new/lists.png" alt="" />
        <span>{{ showProductName() }}/USDT</span>
        <!-- <span
          class="range"
          :style="{
            color:
              productNowInfo[activeIndex].BasicPrice -
                productNowInfo[activeIndex].Price >
              0
                ? basicConfig.FallColour
                : basicConfig.RiseColour
          }"
        >
          {{ getRole() }}%
        </span> -->
      </div>
      <div>
        <img
          src="@/assets/new/trade1.png"
          alt=""
          v-if="productNowInfo[activeIndex].IsCoin"
          @click="goCoin"
        />
        <img
          src="@/assets/new/gang1.png"
          alt=""
          v-if="productNowInfo[activeIndex].IsSecondContract"
          @click="goProduct"
        />
        <img
          src="@/assets/new/datamap.png"
          alt=""
          @click="
            $router.push({
              name: 'LKLine',
              query: {
                Id: productNowInfo[activeIndex].Id
              }
            })
          "
        />
        <img
          src="@/assets/new/icon3.png"
          alt=""
          @click="
            $router.push({
              name: 'LRecord',
              query: {
                Id: productNowInfo[activeIndex].Id
              }
            })
          "
        />
      </div>
    </header>
    <!-- <div style="height:20px;background: #131f30"></div> -->
    <div class="userAction" v-if="IsShowShare">
      <div class="box">
        <div class="usertop">
          <div class="left">
            <!-- 用户持仓(USDT) -->
            {{ $t('trade').yonghuchicang }}(USDT)
          </div>
          <div class="right">
            <!-- 可用(USDT) -->
            {{ $t('trade').keyong }}(USDT)
          </div>
          <div class="risk">
            <!-- 风险率 -->
            {{ $t('trade').fxl }}
            <van-icon @click="show2 = true" name="warning-o" />
            <van-dialog
              className="message2"
              v-model="show2"
              :message="$t('trade').fxlts"
              :confirmButtonText="$t('public').sure"
              show-cancel-button
            >
              <!-- closeOnClickOverlay="true" -->
            </van-dialog>
          </div>
        </div>
        <div class="userbottom">
          <div class="left">
            {{ this.list2.Position }}
          </div>
          <div class="right">
            {{ Number(LegalAmountInfo.USDT).toFixed(4) }}
          </div>
          <div class="risk">{{ this.list2.Hazard }}%</div>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="order-box">
        <div class="buy">
          <div
            class="btn"
            @click="changeMode(0)"
            :style="{
              background: mode === 0 ? basicConfig.RiseColour : null,
              color: mode === 0 ? '#fff' : null
            }"
          >
            {{ $t('trade').Buy }}
          </div>
          <div class="line"></div>
          <div
            class="btn"
            @click="changeMode(1)"
            :style="{
              background: mode === 1 ? basicConfig.FallColour : null,
              color: mode === 1 ? '#fff' : null
            }"
          >
            {{ $t('trade').Sell }}
          </div>
        </div>
        <div class="buy-mode">
          <div class="item" @click="showActions = true">
            {{ actions[activeSelect] }}
            <img src="@/assets/new/pull.png" alt="" />
          </div>
          <div class="right item" @click="showMultipleSelect = true">
            {{ multipleActions[multipleSelect] }}
            <img src="@/assets/new/pull.png" alt="" />
          </div>
        </div>
        <div class="box">
          <div class="input" v-if="activeSelect === 0">
            <input
              v-model="form.Price"
              :placeholder="$t('trade').jiage"
              type="number"
            />
          </div>
          <div class="input mark" v-else>{{ $t('trade').zyjg }}</div>
          <div class="proportion">
            <span>{{ $t('trade').ykbl }}(%)：</span>
            <div class="input num">
              <input
                v-model="form.PriceProportion"
                type="number"
                :placeholder="$t('trade').bili"
              />
            </div>
          </div>
          <div class="select-box">
            <span
              v-for="(item, index) in ContractRatio"
              :key="`c${index}`"
              @click="handleRage(index)"
              :class="{ activeItem: activeItem === index }"
            >
              {{ item }}%
            </span>
          </div>
          <p>{{ $t('trade').zsj }}：{{ stopLose }}</p>
          <p>{{ $t('trade').zyj }}：{{ stopProfit }}</p>
          <!-- <p>1.00{{ showProductName() }}</p> -->
          <div class="jiaoyi">
            <span>{{ $t('trade').jyss }}:</span>
            <div class="input num">
              <input
                v-model="form.Num"
                type="number"
                :placeholder="$t('trade').shuliang"
              />
            </div>
          </div>
          <div class="validate">{{ $t('trade').bzj }}：{{ amount }} USDT</div>
          <div class="validate">
            {{ $t('trade').sxf }}：{{
              form.Num
                ? (
                    (form.Price * form.Num * basicConfig.ContractCharge) /
                    100
                  ).toFixed(2)
                : 0
            }}
            USDT
          </div>
          <div class="validate">
            {{ $t('trade').keyong }}：{{ LegalAmountInfo.USDT }} USDT
          </div>
          <div
            class="btn"
            @click="submit"
            :style="{
              background:
                mode === 0 ? basicConfig.RiseColour : basicConfig.FallColour
            }"
          >
            {{ mode === 0 ? $t('trade').Buy : $t('trade').Sell }}（{{
              mode === 0 ? $t('trade').long : $t('trade').short
            }}）
          </div>
        </div>
      </div>
      <div class="data">
        <div class="title">
          <span> {{ $t('trade').jiage }}</span>
          <span> {{ $t('trade').shuliang }}</span>
        </div>
        <div class="item top" v-for="(item, iIndex) in topData" :key="iIndex">
          <span :style="{ color: getColor(1) }">{{ item.price }}</span>
          <span>{{ item.num }}</span>
        </div>
        <div
          class="price"
          :style="{
            color: mode === 1 ? basicConfig.FallColour : basicConfig.RiseColour
          }"
        >
          {{ productNowInfo[activeIndex].Price }}
        </div>
        <div
          class="item bottom"
          v-for="(item, bIndex) in bottomData"
          :key="`b${bIndex}`"
        >
          <span :style="{ color: getColor(2) }">{{ item.price }}</span>
          <span>{{ item.num }}</span>
        </div>
      </div>
    </div>
    <Trust :productId="productNowInfo[activeIndex].Id" ref="trust" />
    <van-popup class="popup" v-model="showActions" position="bottom">
      <van-picker
        show-toolbar
        :default-index="activeSelect"
        :columns="actions"
        @confirm="handleSelect"
        @cancel="showActions = false"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
      />
    </van-popup>
    <van-popup class="popup" v-model="showMultipleSelect" position="bottom">
      <van-picker
        show-toolbar
        :columns="multipleActions"
        @confirm="handleMultipleSelect"
        @cancel="showMultipleSelect = false"
        :confirm-button-text="$t('public').sure"
        :cancel-button-text="$t('public').cancel"
      />
    </van-popup>

    <van-popup
      v-model="show"
      position="left"
      :style="{ width: '262px', height: '100vh' }"
    >
      <PopupInfo :productNowInfo="productNowInfo" @change="changeProduct" />
    </van-popup>
  </div>
</template>

<script>
import PopupInfo from './components/popupInfo'
import Trust from './components/trust'
import productApi from '@/api/product'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
const nodata = [
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  },
  {
    price: '--',
    num: '--'
  }
]

export default {
  components: {
    PopupInfo,
    Trust
  },
  data() {
    return {
      IsShowShare: true,
      IsHazardshow: true,
      list2: {},
      show2: false,
      mode: 0,
      value: null,
      activeItem: null,
      activeImg: null,
      show: false,
      productList: [],
      activeIndex: 0,
      form2: {
        ProductId: null
      },
      form: {
        ProductId: null,
        OrderType: null,
        BuyDirection: null,
        Num: null,
        Multiple: null,
        PriceProportion: null,
        Price: null
      },
      activeSelect: 1,
      productNowInfo: [
        {
          BasicPrice: 0
        }
      ],
      showMultipleSelect: false,
      multipleSelect: 0,
      multipleActions: [],
      DepthData: {},
      showActions: false,
      actions: [this.$t('trade').xj, this.$t('trade').sj],
      LegalAmountInfo: [],
      ContractRatio: [],
      topData: nodata,
      imgs: [
        require('@/assets/new/pan1.svg'),
        require('@/assets/new/pan2.svg'),
        require('@/assets/new/pan3.svg')
      ],
      bottomData: nodata
    }
  },
  // created() {
  //   const IsHazard = JSON.parse(localStorage.getItem('IsHazard'))
  //   // console.log(IsHazard)
  //   this.IsHazardshow = IsHazard
  //   console.log(this.IsHazardshow)
  // },
  created() {
    /* 判断风险率显示隐藏 */
    this.IsShowShare = JSON.parse(this.basicConfig.IsShowShare)
    // console.log(this.IsShowShare)
  },
  watch: {
    mode() {
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      this.form.Num = null
    },
    activeSelect() {
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      this.form.Num = null
    },
    productId(Val) {
      this.form.productId = Val
    }
  },
  computed: {
    ...mapGetters(['connection', 'basicConfig', 'lang']),

    amount() {
      const rolt = parseInt(this.multipleActions[this.multipleSelect])
      return parseInt((this.form.Price / rolt) * this.form.Num)
    },
    stopLose() {
      const Price = Number(this.form.Price)
      if (this.form.PriceProportion) {
        if (this.mode) {
          return (
            Price +
            ((Price / parseInt(this.multipleActions[this.multipleSelect])) *
              Number(this.form.PriceProportion)) /
              100
          ).toFixed(2)
        } else {
          return (
            Price -
            ((Price / parseInt(this.multipleActions[this.multipleSelect])) *
              Number(this.form.PriceProportion)) /
              100
          ).toFixed(2)
        }
      } else {
        return '0.00'
      }
    },

    stopProfit() {
      const Price = Number(this.form.Price)
      if (this.form.PriceProportion) {
        if (this.mode) {
          return (
            Price -
            (Price / parseInt(this.multipleActions[this.multipleSelect])) *
              Number(this.form.PriceProportion / 100)
          ).toFixed(2)
        } else {
          return (
            Price +
            (Price / parseInt(this.multipleActions[this.multipleSelect])) *
              Number(this.form.PriceProportion / 100)
          ).toFixed(2)
        }
      } else {
        return '0.00'
      }
    }
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.off(this.productNowInfo[this.activeIndex].Name)
      this.connection.invoke('SendMsg', '')
    }
  },
  mounted() {
    this.ContractRatio = this.basicConfig.ContractRatio.split(',')
    let ContractLeverage = this.basicConfig.ContractLeverage.split(',')
    ContractLeverage.forEach((item, index) => {
      ContractLeverage[index] = item + 'X'
    })
    this.multipleActions = ContractLeverage
    this.firstEnter = false
    if (this.$route.query.mode) {
      this.mode = 1
    }
    this.getList()
    this.getUserInfo()

    this.submit()
    //console.log(this.ProductId)
    // this.changeProduct(0)
  },
  methods: {
    goCoin() {
      sessionStorage.setItem('coin', this.productNowInfo[this.activeIndex].Id)
      this.$router.push({
        name: 'Trade'
      })
      //其他地方使用，获取表单缓存数据
      // this.IsHazardshow = this.IsHazard

      // this.IsHazardshow = localStorage.getItem('IsHazard')
    },
    goProduct() {
      sessionStorage.setItem(
        'product',
        this.productNowInfo[this.activeIndex].Id
      )
      this.$router.push({
        name: 'Product'
      })
    },
    changeMode(index) {
      this.mode = index
    },
    handleRage(index) {
      if (this.activeItem === index) {
        this.activeItem = null
        this.form.PriceProportion = null
      } else {
        this.activeItem = index
        this.form.PriceProportion = Number(this.ContractRatio[this.activeItem])
      }
    },
    async submit() {
      if (!this.form.Price) {
        this.$toast(this.$t('trade').tips)
        return
      }
      if (!this.form.Num) {
        this.$toast(this.$t('trade').tips1)
        return
      }
      this.form.OrderType = this.activeSelect ? 'Present' : 'Limit'
      this.form.BuyDirection = this.mode ? 'Sell' : 'Buy'
      this.form.ProductId = this.productNowInfo[this.activeIndex].Id
      this.form2.ProductId = this.productNowInfo[this.activeIndex].Id
      console.log(this.form2)
      this.form.Price = Number(this.form.Price)
      this.form.Num = Number(this.form.Num)
      this.form.PriceProportion = this.form.PriceProportion
        ? Number(this.form.PriceProportion)
        : null

      this.form.Multiple = parseInt(this.multipleActions[this.multipleSelect])
      await productApi.ContractCreate(this.form)
      this.$toast(this.$t('moneyAddr').success)
      setTimeout(() => {
        this.getUserInfo()
        this.$refs.trust.reset()
      }, 500)
      this.gohazard()
    },
    async getUserInfo() {
      const res = await userApi.userInfo()
      console.log(res)
      this.LegalAmountInfo = res.LegalAmountInfo
      // this.LegalAmountInfo = res.LegalAmountInfo
    },
    async gohazard() {
      const res = await productApi.hazard(this.form2)
      // console.log(this.form.ProductId)
      // console.log(res, 1111111111)
      this.list2 = res
    },
    getRole() {
      const Role = (
        ((this.productNowInfo[this.activeIndex].Price -
          this.productNowInfo[this.activeIndex].BasicPrice) /
          this.productNowInfo[this.activeIndex].Price) *
        100
      ).toFixed(2)
      return Role > 0 ? `+${Role}` : Role
    },
    getColor(index) {
      if (index === 1) {
        if (this.activeImg === 1) {
          return this.basicConfig.RiseColour
        } else {
          return this.basicConfig.FallColour
        }
      } else {
        if (this.activeImg === 2) {
          return this.basicConfig.FallColour
        } else {
          return this.basicConfig.RiseColour
        }
      }
    },

    handleSelect(index, value) {
      this.showActions = false
      this.activeSelect = value
    },
    handleMultipleSelect(index, value) {
      this.showMultipleSelect = false
      this.multipleSelect = value
    },
    firstSetTabProduct() {
      if (this.firstEnter) {
        return
      }
      this.productList.forEach((item, index) => {
        if (item.Id === this.ProductId) {
          this.firstEnter = true
          this.activeIndex = index
          return
        }
      })
      this.form.Price = this.productNowInfo[this.activeIndex].Price
    },
    async getList() {
      this.productList = await productApi.ContractProducts()
      const ProductId = sessionStorage.getItem('leverage')
      this.ProductId = ProductId ? Number(ProductId) : this.productList[0].Id
      this.productNowInfo = this.productList
      this.firstSetTabProduct()
      this.signalRStart()
      this.form2.ProductId = this.ProductId
      this.gohazard(this.form2)
    },
    // 选择产品
    changeProduct(index) {
      this.show = false
      this.connection.off(this.productNowInfo[this.activeIndex].Name)
      this.activeIndex = index
      this.ProductId = this.productList[index].Id
      this.form.Price = this.productNowInfo[this.activeIndex].Price
      sessionStorage.setItem('leverage', this.ProductId)
      this.connection.invoke(
        'SendMsg',
        this.productNowInfo[this.activeIndex].Name
      )
      this.topData = nodata
      this.bottomData = nodata
      setTimeout(() => {
        this.$refs.trust.reset()
      }, 10)
    },
    async getSecondData(data) {
      let productNowInfo = data
      let productList = [...this.productList]
      productNowInfo.forEach((item) => {
        productList.some((pItem) => {
          if (pItem.Id === item.ProductId) {
            pItem.Price = item.Price
            return true
          }
        })
      })

      this.productNowInfo = productList
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke(
            'SendMsg',
            this.productNowInfo[this.activeIndex].Name
          )
        }
      }, 100)
      connection.on('secondPrices', function(res) {
        that.getSecondData(res)
      })
      connection.on('Depth', function(res) {
        that.getDepthData(res)
      })
    },
    getDepthData(res) {
      let that = this
      const res1 = JSON.parse(res)
      if (res1.Name === that.productNowInfo[this.activeIndex].Name) {
        const arr = res1['bids']
        const arr1 = res1['asks']
        let bids = []
        let asks = []
        arr.forEach((iitem) => {
          bids.push({
            price: iitem[0],
            num: iitem[1]
          })
        })
        arr1.forEach((iitem) => {
          asks.push({
            price: iitem[0],
            num: iitem[1]
          })
        })
        this.DepthData.bids = bids
        this.DepthData.asks = asks
        switch (this.activeImg) {
          case 1:
            this.bottomData = bids
            this.topData = []
            break
          case 2:
            this.topData = asks
            this.bottomData = []
            break
          default:
            this.topData = []
            for (let i = 0; i < 7; i++) {
              this.topData.push(this.DepthData.asks[i])
            }
            this.bottomData = []
            for (let j = 0; j < 7; j++) {
              this.bottomData.push(this.DepthData.bids[j])
            }
        }
        return
      }
    },
    showProductName() {
      let name = ''
      this.productList.forEach((item) => {
        if (item.Id === this.ProductId) {
          name = item.Name
          // if (this.lang === 'zh') {
          //   name = item.Name
          // } else if (this.lang === 'en') {
          //   name = item.EnglishName
          // } else {
          //   name = item.TraditionalName
          // }
          return
        }
      })
      return name
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-dialog__cancel {
  display: none;
}
::v-deep .van-button__content {
  background-color: #17181e;
  color: white;
}
.message2 {
  background-color: #17181e;
  // color: #010203;
}
.userAction {
  padding: 0.14rem 0.15rem;
  .box {
    background-color: #17181e;
    padding: 0.14rem 0.15rem;
    border-radius: 0.1rem;
    .usertop {
      display: flex;
      align-items: center;
      font-size: 0.15rem;
      text-align: center;
      .left {
        width: 40%;
      }
      .right {
        width: 35%;
      }
      .risk {
        width: 25%;
      }
    }
    .userbottom {
      display: flex;
      align-items: center;
      font-size: 0.12rem;
      text-align: center;
      margin-top: 0.15rem;
      // line-height: 0.5rem;
      .left {
        width: 40%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .right {
        width: 35%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .risk {
        width: 25%;
      }
    }
  }
}
.container {
  padding-top: 50px;
  color: #f2f5ff;
  .popup {
    ::v-deep .van-picker {
      background: #020406;
      .van-picker__toolbar {
        background: #010203;
      }
    }
    ::v-deep .van-picker-column__item {
      color: #fff !important;
    }
    ::v-deep .van-picker-column__item--selected {
      font-size: 20px;
      font-weight: bold;
    }
    ::v-deep .van-picker__mask {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  header {
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;

    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    box-sizing: border-box;
    z-index: 99;
    background: #131f30;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      font-size: 18px;
      font-weight: bold;
    }
    .left {
      display: flex;
      align-items: center;
      .range {
        display: inline-block;
        padding: 1px;
        margin-left: 10px;
        font-size: 12px;
        background: rgba(250, 82, 82, 0.1);
      }
    }
  }
  .top {
    padding: 0.12rem 0 0 0;
    background: #17181e;
    display: flex;
    .order-box {
      padding: 0 14px 15px;
      width: 226px;
      .buy {
        display: flex;
        background: #1a273f;
        position: relative;
        .line {
          width: 15px;
          height: 55px;
          transform: rotate(25deg);
          position: absolute;
          left: calc(50% - 9px);
          top: -10px;
          background: #17181e;
        }
        .btn {
          flex: 1;
          height: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
        }
      }
      .buy-mode {
        padding: 14px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #ffffff;
        img {
          width: 15px;
          height: 15px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 5px;
        }
        .item {
          width: 130px;
          height: 30px;
          padding-right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #4d5568;
          position: relative;
        }
        .right {
          width: 63px;
        }
      }
      .box {
        p {
          padding-top: 10px;
        }
        .jiaoyi {
          display: flex;
          align-items: center;
          span {
            margin: 15px 5px 0 0;
            font-size: 13px;
          }
        }
        .input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 38px;
          width: 100%;
          border: 1px solid #42526a;
          margin-top: 15px;
          input {
            padding: 0 10px;
            width: 91px;
            height: 100%;
            background: transparent;
            border: none;
          }

          span {
            font-size: 16px;
            color: #7286a5;
            margin-right: 10px;
          }
          .control {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            // border-left: 1px solid #42526a;
            &::before {
              content: '';
              width: 1px;
              height: 36px;
              position: absolute;
              left: 0;
              background: #42526a;
            }
            img {
              width: 11px;
              height: 11px;
              padding: 0 10px;
            }
            .del {
              border-right: 1px solid #42526a;
            }
          }
        }
        .num {
          width: 133px;
          height: 30px;
        }
        .mark {
          padding-left: 10px;
          background: #000000;
        }
        .proportion {
          display: flex;
          align-items: center;
          margin-top: 15px;
          .input {
            margin-top: 0;
            width: 100px;
          }
        }
        .validate {
          font-size: 13px;
          margin: 10px 0;
        }
        .select-box {
          display: flex;
          margin: 10px 0;
          span {
            width: 40px;
            height: 19px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            border: 1px solid #42526a;
            &:nth-of-type(1) {
              margin-left: 0;
            }
          }
        }
        .btn {
          width: 195px;
          height: 40px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
        }
        .activeItem {
          background: #4f7bb1;
        }
      }
    }
    .data {
      flex: 1;
      padding-right: 15px;
      .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      .item {
        height: 27px;
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      .price {
        font-size: 18px;
      }
      .imgs {
        display: flex;
        .img {
          border: 1px solid #3a4163;
          padding: 1px;
          margin-right: 5px;
          img {
            width: 15px;
            height: 15px;
          }
        }
        .activeImg {
          border-color: #357ce1;
        }
      }
    }
  }
}
</style>
